import React, { useState } from "react";
import { styled } from "styled-components";
import logo from "../Logo/Untitled_design-removebg-preview (1).png";
import spreadlove from '../Logo/undraw_spread_love_re_v3cl.svg';
import { Link } from "react-router-dom";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [formLoading, setformLoading] = useState();
  const [formError, setFormError] = useState("");
  const [btn,setBtn]=useState(false);
  let form_success_validation = 0;
  const submitHandler = (e) => {
    e.preventDefault();
    setformLoading(true);
    setBtn(true);
  


    ///_____Sending the request to backend ____________///
    const data = { name, email, desc };
    try {
      axios
        .post("https://backend-love-lease.vercel.app/api/loveLease/contact-form", data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            form_success_validation=1;
            setFormSubmit(true);
            setBtn(false);
          }
        })
        .catch((err) => {
          if(err.response){
            setFormError(err.response.data.message)
          }
          else if(err.message==='Network Error'){
            setFormError('Seems like there is a issue ,Soon we will fix it');
          }
         
          console.log(err);
          setBtn(false);
          setTimeout(() => {
            setFormError('')
            
          }, 2000);
        });
    } catch (error) {
      setFormError(error);
    }

    //------------------------------------------------------------//

    
   
    setformLoading(false);
  };

  return (
    <>
      <Wrapper className="contact-page">
        <div className="contact-content">
          <p>
            We value your feedback, inquiries, and any assistance you may
            require. Please don't hesitate to reach out to our dedicated
            customer support team. We are here to assist you and ensure your
            experience with loveLease is exceptional.
          </p>
        </div>
        <div className="contact-container">
          <div className="contact-container-form">
            {!formSubmit && (
              <form onSubmit={submitHandler}>
                <div className="form-controls">
                  <input
                    placeholder="Name"
                    type="text"
                    id="name"
                    onChange={(e) => {
                      return setName(e.target.value);
                    }}
                    // required
                  ></input>
                </div>
                <div className="form-controls">
                  <input
                    placeholder="Email"
                    type="email"
                    id="email"
                    onChange={(e) => {
                      return setEmail(e.target.value);
                    }}
                    // required
                  ></input>
                </div>
                <div className="form-controls">
                  <textarea
                    placeholder="Description"
                    type="text"
                    id="description"
                    onChange={(e) => {
                      return setDesc(e.target.value);
                    }}
                    // required
                  ></textarea>
                </div>

                <div>
                  {formError ?<p>{formError}</p>:''}
                  <button className="btn btn--love" type="submit" disabled={btn}>
                    {btn?'Loading':'Submit'}
                  </button>
                </div>
              </form>
            )}
            {formSubmit && (
              <div className="form-submit-content">
                <p style={{ margin: "0 0 20px 30px" }}>
                  Your details has been successfully submitted. Thanks!{" "}
                </p>
                <button className="btn btn--love" >
                  <Link  to="/">Home</Link>
                </button>
              </div>
            )}
          </div>
          <div className="contact-container-image">
            <img src={spreadlove} alt="love-lease-contact form logo"></img>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Contact;

const Wrapper = styled.div`
  // height:700px;
  max-height: auto;
  a {
    text-decoration: none;
    color: white;
  }
  .contact-content {
    // background:#f11926;
    background: hsl(22, 31%, 88%);
    color: #hsl(22, 31%, 88%);
    font-weight: 600;
    border-radius: 5px;
    margin: 10px 10px 10px 10px;
    padding: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 1.1);
  }
  .contact-content > p {
    text-align: justify;
    margin: 8px;
  }
 
  .contact-container {
    border-radius: 8px;
    margin: 0 2rem 2rem 2rem;
    margin-top: 5rem;
    // width:60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #00E2E2;
  }
  .contact-container-form {
    margin-top: 20px;
    width: 60%;
  }
  .contact-container-image img{
    width:80%;
    padding:4%;
  }
  .form-controls {
    margin: 10px;
    width: 100%;
  }
  input,
  textarea {
    width: 70%;
    max-width: 70%;
    font-family: Arial;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
  textarea {
    min-width: auto;
    min-height: 110px;
    max-width: 70%;
    max-height: 110px;
  }

  .btn {
    padding: 10px;
    // font-weight: 300;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    transition: all 0.5s ease;
    border-radius: 3px;
    border-style: none;
  }

  .btn--love {
    color: #fff;
    // padding-right: 0;
    // background-color: #c0392b;
    background-color: #eb4e58;
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 100%, 0 100%);
  }
  button:disabled{
    background:white;
    color:#eb4e58;
  }

  .btn--love:hover {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    cursor: pointer;
  }
  .btn--love:after {
  }
  // .btn--love:after {
  //   // content: "\f004";
  //   color: #e74c3c;
  //   font-family: FontAwesome;
  //   // display: inline-block;
  //   // position: relative;
  //   right: -55px;
  //   transition: all 0.2s ease;
  // }

  .btn--love:hover:after {
    margin: -5px 15px;
    right: 0px;
  }
  .form-submit-content {
    color: White;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 980px) {
    margin:0;
    .contact-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .contact-container-form {
      width: -webkit-fill-available;
      margin: 20px 16px 20px 0px;
    }
    input {
      width: 100%;
    }
  }
`;
