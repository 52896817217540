import React from "react";

export const links=[
    {
        id:1,
        url:'/',
        text:'Home',
    },
    {
        id:2,
        url:'/services',
        text:'Services',

    },
    {
        id:3,
        url:'/about-us',
        text:'About Us',
    },
    {
        id:4,
        url:'/add-your-profile',
        text:'Add Profile',
    },
    {
        id:5,
        url:'/profiles',
        text:'Profiles',
    },
    {
        id:6,
        url:'/Contact-us',
        text:'Contact',
    }
];