import { useState } from "react";
import React from "react";

export const ProfileContext = React.createContext({
  profilesUpdater: () => {},
});

export const ProfilecontextProvider = (props) => {
  const [userprofiles, setUserProfiles] = useState([]);

  const profilesUpdater = (data) => {
    setUserProfiles(data);
  };

  return (
    <ProfileContext.Provider value={{ userprofiles, profilesUpdater }}>
      {props.children}
    </ProfileContext.Provider>
  );
};
