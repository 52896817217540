import { styled } from "styled-components";

const Identity = (props) => {
  
  return (
    <>
      <Wrapper>
        <div>
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                country: e.target.value.charAt(0).toUpperCase()+e.target.value.slice(1),
              }));
            }}
            value={props.inputValue.country}
          ></input>
        </div>
        <div>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                city:e.target.value.charAt(0).toUpperCase()+e.target.value.slice(1),
              }));
            }}
            value={props.inputValue.city}
          ></input>
        </div>
        <div>
          <label htmlFor="linkedin">LinkedIn</label>
          <input
            type="text"
            id="linkedin"
            placeholder="LinkedIn profile url"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                linkedInURL: e.target.value,
              }));
            }}
            value={props.inputValue.linkedInURL}
          ></input>
        </div>
        <div>
          <label>Height</label>
          <input
            type="number"
            className="height"
            placeholder="in cm"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                height: e.target.value,
              }));
            }}
            value={props.inputValue.height}
            step="any"
            onWheel={(e) => {
              e.target.blur();
            }}
          ></input>
        </div>
        <div>
          <button
            onClick={props.prevStep}
            className="btn"
            style={{ margin: "5px" }}
          >
            Prev
          </button>
          <button
            onClick={props.nextStep}
            className="btn"
            style={{ margin: "5px" }}
          >
            Next
          </button>
        </div>
      </Wrapper>
    </>
  );
};

export default Identity;

const Wrapper = styled.div``;
