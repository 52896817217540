import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import ProfileContainer from "../components/ProfileContainer";
// import { profiles } from "../data/profilesmock";
import axios from "axios";
import { ProfileContext } from "../context/profile-context";
import LoadingAnimation from "../LottieAnimation/LoadingAnimation";

const Profiles = () => {
  const [city, setCityFilter] = useState(false);
  const [dbprofiles, setProfiles] = useState([]);
  const [error, setError] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);

  const ctx = useContext(ProfileContext);

  useEffect(() => {
    axios
      .get("https://backend-love-lease.vercel.app/api/profiles-lists", {
        headers: {
          Accept: "application/octet-stream",
        },
      })
      .then((response) => {
        
        setTimeout(() => {
          setProfiles(response.data);
          setLoading(false);
        }, 2000);
        ctx.profilesUpdater(response.data);
      })
      .catch((err) => {
        setError(err.message);
        console.log(err);
      });
  }, []);

  /********************to filter data based on the selected city value**************/
  const filteredData = dbprofiles.filter((data) => !city || data.city === city);

  /***************** To get the unique city values ***********************/
  const uniqueCities = Array.from(new Set(dbprofiles.map((city) => city.city)));

  return (
    <>
      <Wrapper>
        {!error && (
          <div>
            <div className="service-header">
              <h2>"Explore the Route to Heartfelt Companionship"</h2>
            </div>
            <div className="filter-location">
              <label htmlFor="location">City</label>

              <select
                name="location"
                id="location"
                value={city}
                onChange={(e) => {
                  setCityFilter(e.target.value);
                }}
              >
                <option value="">All</option>
                {uniqueCities?.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
              </select>
            </div>
            {loading && (
              <div style={{weight:"100px"}}>
                <LoadingAnimation />
              </div>
            )}
            {<div className="profile-banner">
              {(dbprofiles.length <=0 && !loading) && (
                <div>
                  {" "}
                  <h1
                    style={{
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No profiles available at this moment
                  </h1>{" "}
                </div>
              )}
              {!city &&
                dbprofiles.map((data) => {
                  return (
                    <ProfileContainer
                      key={data._id}
                      value={data}
                      image={imageData}
                    />
                  );
                })}
              {city &&
                filteredData.map((data) => {
                  return <ProfileContainer key={data._id} value={data} />;
                })}
            </div>}
          </div>
        )}
        {error && (
          <div>
            <h1
              style={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Seems like there is a issue. Soon, we will fix it.
            </h1>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Profiles;

const Wrapper = styled.div`
  .profile-banner {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .service-header {
    background: #00E2E2;
    color: #345b5e;
    padding: 20px;
    margin: 30px 0;
  }
  .service-header,h2{
    color:black;
  }
  .filter-location {
    font-weight: 500;
    label {
      margin: 0 10px;
      font-size: 20px;
      vertical-align: middle;
    }
    select {
      padding: 8px;
      font-size: 15px;
    }
  }
`;
