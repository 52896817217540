import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../Logo/Purple Yellow Pink Girl Working Animated Portrait Instagram Post.json";

const SeekingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
      />
     </div>
  );
};

export default SeekingAnimation;