import React, { useState } from "react";
import { styled } from "styled-components";
import logo from "../Logo/Note.png";
import axios from "axios";
import Confirmation from "../multistepform/Confirmation";
import Personal from "../multistepform/Personal";
import Identity from "../multistepform/Identity";

const AddProfile = () => {

//***************** loading /error state handling ******************************/
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //***************** userValue state handling ******************************/
  const [inputValue, setInputValue] = useState({
    username: "",
    dob: "",
    email: "",
    contact: "",
    country: "",
    city: "",
    linkedInURL: "",
    smoker: false,
    drinker: false,
    height: "",
    weight: "",
    charge_per_day: "",
    about_yourself: "",
  });

  const [formsubmit, setFormSubmit] = useState(false); // form submit state handler

  const [displayimage, setDisplayImage] = useState(); // display image state handling

  //---------Dislpay Image Handler------------------
  const displayImageHanlder = async (e) => {
    setDisplayImage(e.target.files[0]);
  };


  //***************** checkbox state handling function ******************************/
  const drinkercheckboxHandler = () => {
    setInputValue((prevInput) => ({
      ...prevInput,
      drinker: !inputValue.drinker,
    }));
  };
  const smokercheckboxHandler = () => {
    setInputValue((prevInput) => ({
      ...prevInput,
      smoker: !inputValue.smoker,
    }));
  };

  //***************** multiStep form values handler ******************************/
  var [step, setStep] = useState(1);
  const prevStep = () => {
    setStep((step = step - 1));
  };
  const nextStep = () => {
    setStep((step = step + 1));
  };

  // ---------------form submit Hanlder-------------------------
  const formsubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let username = inputValue.username;
    let dob = inputValue.dob;
    let email = inputValue.email;
    let contact = inputValue.contact;
    let country = inputValue.country;
    let city = inputValue.city;
    let linkedInURL = inputValue.linkedInURL;
    let smoker = (inputValue.smoker) ? 'Yes' : 'No';
    let drinker = (inputValue.drinker) ? 'Yes' : 'No';
    let height = inputValue.height;
    let weight = inputValue.weight;
    let charge_per_day = inputValue.charge_per_day;
    let about_yourself = inputValue.about_yourself;

    const data = {
      username,
      dob,
      email,
      contact,
      country,
      city,
      linkedInURL,
      smoker,
      drinker,
      height,
      weight,
      charge_per_day,
      about_yourself,
      display_image: displayimage,
    };
    console.log(data);
    axios
      .post(
        "https://backend-love-lease.vercel.app/api/loveLease/add-your-profile",

        data,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setFormSubmit(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setError(err.response.data.message);
        } else if (err.message === "Network Error") {
          setError("Seems like there is a issue ,Soon we will fix it");
        }
        setTimeout(() => {
          setError("");
          setLoading(false);
        }, 2000);
      });
  };

  return (
    <>
      <Wrapper>
        <div className="header">
          <h2>Your Journey to Meaningful Connections</h2>
        </div>
        <div className="form-container">
          <div className="form-logo">
            <img
              src={logo}
              alt="Note_contract_logo"
              className="form-note-logo"
            />
          </div>
          <div className="multi-step-form">
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <>
                      {!formsubmit && (
                        <Personal
                          nextStep={nextStep}
                          setInputValue={setInputValue}
                          inputValue={inputValue}
                          drinkercheckboxHandler={drinkercheckboxHandler}
                          smokercheckboxHandler={smokercheckboxHandler}
                        />
                      )}
                    </>
                  );
                case 2:
                  return (
                    <>
                      {!formsubmit && (
                        <Identity
                          nextStep={nextStep}
                          prevStep={prevStep}
                          setInputValue={setInputValue}
                          inputValue={inputValue}
                        />
                      )}
                    </>
                  );

                case 3:
                  return (
                    <>
                      <Confirmation
                        prevStep={prevStep}
                        displayImageHanlder={displayImageHanlder}
                        displayimage={displayimage}
                        formsubmit={formsubmit}
                        formsubmitHandler={formsubmitHandler}
                        loading={loading}
                        error={error}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                      />
                    </>
                  );
                default:
                // do nothing
              }
            })()}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default AddProfile;

const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: rgb(255, 191, 0);
  }
  .header {
    background: #0d0d40;
    color: rgb(255, 191, 0);
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #eaded7;
    border-radius: 10px;
    padding: 15px;
    margin: 20px 0;
  }
  form > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image-preview {
    width: 400px;
    height: 400px;
  }
  label {
    font-size: 20px;
  }
  input,
  textarea {
    font-size: 17px;
    width: 95%;
    max-width: 95%;
    margin: 20px;
    font-family: Arial;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
  .checkbox-control {
    display: inline-block;
    margin: 0px 30px;
    align-items: baseline;

    input {
      width: 30px;
      height: 20px;
    }
  }
  textarea {
    height: 100px;
    max-height: 110px;
  }
  .btn {
    border: none;
    padding: 11px;
    font-size: 20px;
    border-radius: 5px;
    background: #0d0d40;
    color: rgb(255, 191, 0);
  }
  .btn:hover {
    cursor: pointer;
  }

  .image-preview {
    display: flex;
    align-items: center;
    flex-direction: column;
    button {
      width: 30%;
      border: none;
      font-size: 1rem;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 6px;
    }
    img {
      width: 300px;
      height: 300px;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media screen and (max-width: 1130px) {
    label {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 800px) {
    .form-logo {
      display: none;
    }
    input {
      width: 100%;
    }
  }
`;
