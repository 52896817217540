import React, { useContext } from "react";
import styled from "styled-components";
import { links } from "../data/link";
import { FaBars } from "react-icons/fa";
import logo from "../Logo/Your_paragraph_text__2_-removebg-preview.png";
import { Link } from "react-router-dom";
import { Context } from "../context/Context";

const Navbar = () => {
     const { openSidebar } = useContext(Context);

  return (
    <Wrapper>
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="Platform logo" className="logo"></img>
          </Link>
          {/* <IconTint src={logo} alt="familian logo" className="logo"  maxHeight="47px" maxWidth="230px" /> */}

         
        </div>
        <button className="nav-toggle" onClick={openSidebar}>
            <FaBars />
          </button>

        <div className="nav-links-container">
          <ul className="nav-links">
            {links.map((link) => {
              return (
                <li key={link.id}>
                    <ul className="links">
                  <Link to={`${link.url}`}>{link.text}</Link>
                  </ul>
                  {/* <a href={link.url}>{link.text}</a> */}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;

const Wrapper = styled.nav`
  width: 100%;
  max-width:100%;
 
  // backdrop-filter: blur(16px);
  background: white;
  padding:0.5rem;

  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  .nav-center {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    vertical-align: middle;
    margin: 0 1rem;
  }
  .nav-header {
    margin-right: auto;
  }
  .nav-header img {
    width: 181px;
    height: 59px;
  }
  .nav-toggle {
    display: none;
  }
  .nav-toggle {
    font-size: 1.5rem;
    color: #f11926;
    background: transparent;
    border-color: transparent;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  .nav-links-container {
    margin: 1rem;
  }
  .nav-links {
    display: flex;
    list-style-type: none;
  }
  .links a{
    color:#f11926;
    font-size:20px;
    font-weight:500;
  }

  .links :hover {
    font-weight:700;
    background: #f11926;
    color:white;
    padding: 0.5rem;
    border-radius: 5px;
    transition: 0.15s padding ease-out, 0.15s margin ease-out,0.15s border ease-out;
  }
  .nav-links a {
    text-decoration: none;
    margin:10px;
  }
  @media screen and (max-width: 800px) {
   .nav-links-container{
    display:none;
   }
   .nav-header{
    display:flex;

   }
   .nav-toggle{
    display:flex;
   }
   .nav-toggle : hover{
   transform: rotate(90deg);
   }
  }
`;
