export const slidertopic = [
  `In the world of dating and relationships, choices are often
  influenced by external factors, societal norms, or the
  limitations of traditional methods.
  In loveLease, we put the control back in your
  hands. Here, you have the authority to select the companion.`,

  `Our platform offers a diverse selection of companions.
   Whether you're seeking a companion for an
evening event, a weekend getaway, or simply a heartfelt
conversation, our extensive selection ensures that you find
someone who truly aligns with your vision.`,

  `Our companion
profiles provide a comprehensive view of each individual, from
their hobbies and passions to their availability and pricing.
With this clarity, you can confidently select the companion
who suits your preferences and expectations.`,

  ` Select Your Companion, Contact Your
Companion,
Negotiate on Your Terms ,Reach an Agreement. Once both parties are satisfied, you
can reach a mutual agreement on the terms of your
companionship experience.`,
];
