import axios from "axios";
import React, { useEffect, useState } from "react";

// function convertToBase64(file) {
//   return new Promise((resolve, reject) => {
//     const fileReader = new FileReader();
//     fileReader.readAsDataURL(file);
//     fileReader.onload = () => {
//       resolve(fileReader.result);
//     };
//     fileReader.onerror = (error) => {
//       reject(error);
//     };
//   });
// }

const Testing = () => {
  const [imageFile, setImageFile] = useState();
  const [images, setImages] = useState([]);
  const [imageurl,setImageDataURL]=useState('')
  let url;

  const handleFileUpload = async (e) => {
    setImageFile(e.target.files[0]);
    // const file = e.target.files[0];
    // const base64 = await convertToBase64(file);
    // setImageFile(base64);
  };
  const fileSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);
    axios
      .post("http://localhost:4000/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        
        if (response.data) {
          const blob = new Blob([response.data]);
           url = URL.createObjectURL(blob);
          console.log(url)
          setImageDataURL(url);
      
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("http://localhost:3001/getImage")
      .then((response) => {
        setImages(response.data);
        console.log(images);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [images]);

  return (
    <>
      <form onSubmit={fileSubmit}>
        <input
          type="file"
          id="myFile"
          name="filename"
          onChange={handleFileUpload}
        />
        <input type="submit" />
      </form>
      <div>
        {images &&
          images?.map((data) => {
            return (
              <div key={1}>
                <img src={data.image} />
              </div>
            );
          })}
      </div>
      <div>
        <img src='blob:http://localhost:3000/d8801403-df71-4e9c-8489-0d89f1c17737' alt="" />
      </div>
    </>
  );
};

export default Testing;
