import { useState } from "react";
import { styled } from "styled-components";

const Personal = (props) => {
  return (
    <>
      <Wrapper>
        <div>
          <label htmlFor="name">Username</label>
          <input
            type="text"
            placeholder="Username"
            className="username"
            id="name"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                username:
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1),
              }));
            }}
            value={props.inputValue.username}
          ></input>
        </div>

        <div>
          <label htmlFor="dob">DOB </label>
          <input
            type="date"
            id="dob"
            value={props.inputValue.dob}
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                dob: e.target.value,
              }));
            }}
          ></input>
        </div>
        <div>
          <label htmlFor="contact">Contact</label>
          <input
            type="tel"
            id="contact"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                contact: e.target.value,
              }));
            }}
            value={props.inputValue.contact}
          ></input>
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            onChange={(e) => {
              props.setInputValue((prevInput) => ({
                ...prevInput,
                email: e.target.value,
              }));
            }}
            value={props.inputValue.email}
          ></input>
        </div>
        <div className="checkbox-control">
          <label htmlFor="smoker">Smoker</label>
          <input
            type="checkbox"
            id="smoker"
            name="smoker"
            value={props.inputValue.smoker ? "Yes" : "No"}
            onChange={props.smokercheckboxHandler}
            checked={props.inputValue.smoker}
          />
        </div>
        <div className="checkbox-control">
          <label htmlFor="drinker">Drinker</label>
          <input
            type="checkbox"
            id="drinker"
            name="drinker"
            value={props.inputValue.drinker ? "Yes" : "No"}
            checked={props.inputValue.drinker}
            onChange={props.drinkercheckboxHandler}
          />
        </div>
        <div>
          <button onClick={props.nextStep} className="btn">
            Next
          </button>
        </div>
      </Wrapper>
    </>
  );
};

export default Personal;

const Wrapper = styled.div``;
