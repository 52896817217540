import React, { useState } from "react";
import { styled } from "styled-components";

import { slidertopic } from "../data/slidertopic";

const About = () => {
  const [sliderIndex, setSliderIndex] = useState(0);

  return (
    <>
      <Wrapper>
        <div>
          <div className="about-header">
            <h2 className="about-heading">
              Welcome to loveLease.
              <br />
              We’re glad and grateful you’re here.
            </h2>

            <h4 className="header-para">
              At loveLease, we believe that everyone deserves to have someone by
              their side to share experiences
              <br />
              and memories with. Whether you're looking for a companion for a
              special event,
              <br /> a date to a party, or just someone to spend time with,
              we're here to help.
            </h4>
          </div>
          <div>
            <div>
              <h1 className="slide-header">Why loveLease ?</h1>
            </div>
            <div className="slider">
              <h3
                onClick={() => {
                  return setSliderIndex(0);
                }}
                className={sliderIndex === 0 ? "slideractive" : ""}
              >
                Your Choice
              </h3>
              <h3
                onClick={() => {
                  return setSliderIndex(1);
                }}
                className={sliderIndex === 1 ? "slideractive" : ""}
              >
                Boundless Possibilities
              </h3>
              <h3
                onClick={() => {
                  return setSliderIndex(2);
                }}
                className={sliderIndex === 2 ? "slideractive" : ""}
              >
                Transparency
              </h3>
              <h3
                onClick={() => {
                  return setSliderIndex(3);
                }}
                className={sliderIndex === 3 ? "slideractive" : ""}
              >
                Flexibility
              </h3>
            </div>
            <hr className="slider-line" style={{ margin: "0 20px" }}></hr>
            <div className="slider-topic-content">
              {/* <ul><li>{slidertopic[sliderIndex]}</li></ul> */}
              <p>{slidertopic[sliderIndex]}</p>
            </div>

            {/* -------------Mobile responsive Content -------------------------------------- */}
            <div className="slider-responsive">
              <h3>Your Choice</h3>
              <p>{slidertopic[0]}</p>
              <h3> Boundless Possibilities</h3>
              <p>{slidertopic[1]}</p>
              <h3>Transparency</h3>
              <p>{slidertopic[2]}</p>
              <h3>Flexibility</h3>
              <p>{slidertopic[3]}</p>
            </div>
          </div>
          <div className="aboutus-Team">
            <h1 className="team-header">About out Team</h1>
            <h4 className='team-content' >
              Our exceptional team is a group of dedicated individuals with
              diverse backgrounds and expertise, brought together by a shared
              passion for innovation and a commitment to our mission. With our
              Founder and CEO leading the way, our talented professionals in
              technology, creative design, marketing, and customer support work
              collaboratively to bring our vision to life. We value creativity,
              collaboration, and a relentless pursuit of excellence, all of
              which drive us to continually improve and provide the best
              possible solutions to our customers
            </h4>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default About;

const Wrapper = styled.div`
  .about-header {
    background: hsl(22, 31%, 88%);
    margin: 10px 0;
  }
  .about-heading {
    color: #345b5e;
    padding-top: 20px;
    margin: 0 0 20px 0;
  }
  .header-para {
    color: #345b5e;
    padding: 20px;
  }
  .slide-header {
    margin: 20px 20px 0 20px;
    color: #345b5e;
  }
  .slider {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
    h3 {
      padding: 20px;
    }
    h3:hover{
        cursor:pointer;
    }

    .slideractive {
      background: hsl(22, 31%, 88%);
      border-radius: 4px;
    }
  }
  .slider-topic {
    display: flex;
  }
  .slider-topic-content {
    // background:hsl(22, 31%, 88%);
    padding: 10px;
    font-size: 22px;
    margin: 10px 18rem 20px 18rem;
  }
  .aboutus-Team{
    background:white;
    .team-header{
        color:#345b5e;
        margin:2rem 0 1.5rem 0;
    }
    .team-content{
        text-align:justify;
        background:hsl(22, 31%, 88%);
        margin:20px 0;
        padding:4rem;
        border-radius:4px;
        color:#345b5e;
    }
  }
  .slider-responsive {
    display: none;
  }
  @media screen and (max-width: 800px) {
    .slider-line,
    .slider-topic-content,
    .slider {
      display: none;
    }
    .aboutus-Team{
        .team-content{
            padding:1rem;
        }
    }
   
    .slider-responsive {
      display: block;
      margin: 20px;
      h3 {
        margin: 10px;
        border-bottom: 2px solid black;
      }
      p {
        text-align: justify;
      }
    }
  }
`;
