import "./App.css";
import Contact from "./Pages/Contact";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

import Slider from "./components/Slider";
import AddProfile from "./Pages/AddProfile";
import Profiles from "./Pages/Profiles";
import PersonPage from "./Pages/PersonPage";
import Errorpage from "./Pages/ErrorPage";
import Testing from "./Pages/testing";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Sidebar />
      <Slider />

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/add-profile" element={<AddProfile />}></Route>
        <Route path="/profiles" element={<Profiles />}></Route>
        <Route path="/add-your-profile" element={<AddProfile />}></Route>
        <Route path="/detail/:id" element={<PersonPage />}></Route>
        <Route path='*' element={<Errorpage />}></Route>
        <Route path='/testing' element={<Testing />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
