import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Errorpage = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="container">
        <div className="container_code">
          <h1>404</h1>
        </div>
        <div className="container_info">
          <div className="container_para">
            <h2> We can't find that page</h2>
            <p>
              We're fairly sure that page used to be here, <br></br>but seems to
              have gone missing. We do apologise on it's behalf.
            </p>
          </div>
          <div className="container_home">
            <button
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 10rem;
  }
  .container_info {
    margin: 1rem;
    display: flex;
    align-items:center;
  }
  button {
    background: #eb4e58;
    padding: 1rem;
    margin: 20px;
    border-radius: 4px;
    border: none;
    font-size: 1.5rem;
    color: white;
  }
  button:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    .container_info{
         flex-direction:column;
    }
  }
  @media screen and (max-width: 300px) {
    h1 {
        font-size: 5rem;
      }
  }
`;

export default Errorpage;
