import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Confirmation = (props) => {
  return (
    <>
      <Wrapper>
        {props.formsubmit && (
          <div style={{ margin: "20px" }} className="submit-message">
            <h2>
              "Thank you for submitting your profile. We're excited to have you
              on board! You can expect to start receiving emails from potential
              customers very soon. Keep an eye on your inbox for new
              opportunities and connections. If you have any questions or need
              assistance, feel free to reach out to our support team."
            </h2>
            <button style={{ margin: "20px" }} className="btn ">
              <Link to="/">Home</Link>
            </button>
          </div>
        )}
        {!props.formsubmit && (
          <form onSubmit={props.formsubmitHandler}>
            <div>
              <label>Weight</label>
              <input
                type="number"
                className="weight"
                placeholder="in kg"
                onChange={(e) => {
                  props.setInputValue((prevInput) => ({
                    ...prevInput,
                    weight: e.target.value,
                  }));
                }}
                step="any"
                onWheel={(e) => {
                  e.target.blur();
                }}
              ></input>
            </div>
            <div>
              <label>Charge/day</label>
              <input
                type="number"
                className="charge"
                placeholder="in INR"
                onWheel={(e) => {
                  e.target.blur();
                }}
                value={props.inputValue.charge_per_day}
                onChange={(e) => {
                  props.setInputValue((prevInput) => ({
                    ...prevInput,
                    charge_per_day: e.target.value,
                  }));
                }}
              ></input>
            </div>
            <div>
              <label>About Yourself</label>
              <textarea
                type="text"
                className="description"
                placeholder="Cover Letter"
                onChange={(e) => {
                  props.setInputValue((prevInput) => ({
                    ...prevInput,
                    about_yourself: e.target.value,
                  }));
                }}
                value={props.inputValue.about_yourself}
              ></textarea>
            </div>
            <div className="image-uploader">
              <label>Display Image </label>
              <input
                type="file"
                className="display-image"
                onChange={props.displayImageHanlder}
                // value={props.displayimage}
                max-size={1000}
                accept="image/png,image/CR2, image/jpeg,image/heic"
              ></input>
            </div>
            <div className="button-align">
              <button
                onClick={props.prevStep}
                className="btn"
                style={{ margin: "10px" }}
                disabled={props.loading}
              >
                Prev
              </button>
              <button type="submit" className="btn" disabled={props.loading}>
                {props.loading ? "Loading.." : "Submit"}
              </button>
              <p>{props.error}</p>
            </div>
          </form>
        )}
      </Wrapper>
    </>
  );
};

export default Confirmation;

const Wrapper = styled.div`
  .button-align {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;
