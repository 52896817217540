import React, {  useState } from "react";
import logo from "../Logo/Your_paragraph_text__2_-removebg-preview.png";
import styled from "styled-components";
import { RiHomeHeartLine, RiTwitterXLine } from "react-icons/ri";
import { RxLinkedinLogo } from "react-icons/rx";
import { FiInstagram } from "react-icons/fi";
import { FaSquareYoutube } from "react-icons/fa6";
import { GoMail } from "react-icons/go";

import { Link } from "react-router-dom";

const year = new Date().getFullYear();

const Footer = () => {
  const [emailAddress, setEmailAddress] = useState(
    "admin@lovelease.org"
  );

  //-------------------Scroll to Top function---------------------//
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  //-------------Copying the text to Clipboard---------------------//
  const clipboardCopy = (e) => {
    e.preventDefault();
    console.log(e.target.innerText);
    navigator.clipboard.writeText(e.target.innerText);
    setEmailAddress("Copied To Clipboard");
    setTimeout(() => {
      setEmailAddress("admin@lovelease.org");
    }, 1000);
  };

  return (
    <>
      <Wrapper>
        <div className="footer-bar">
          <div className="footer-items">
            <div className="footer-logo">
              <Link to="/">
                <img
                  src={logo}
                  alt="Love Lease Logo"
                  className="logo hovering-pointer"
                ></img>
              </Link>
              <div className="footer-line"></div>

              <div style={{ marginTop: "30px" }}>Coming Soon on</div>
              <div className="social-links">
                <div className="social-link hovering-pointer">
                  <RiTwitterXLine />
                </div>
                <div className="social-link hovering-pointer">
                  <RxLinkedinLogo />
                </div>
                <div className="social-link hovering-pointer">
                  <FiInstagram />
                </div>
                {/* <TbBrandYoutubeFilled /> */}
                <div className="social-link hovering-pointer">
                  <FaSquareYoutube />
                </div>
              </div>
            </div>
          </div>

          <div className="footer-items">
            <h3>Quick Links</h3>
            <Link className="hovering-pointer" to="/about-us">
              About us
            </Link>
            <Link className="hovering-pointer" to="/add-your-profile">
              Add Profile
            </Link>
            <Link className="hovering-pointer" to="/services">
              Services
            </Link>
            <Link className="hovering-pointer" to="/contact-us">
              Contact Us
            </Link>
            <div className="backtop hovering-pointer" onClick={scrollToTop}>
              Back To Top
            </div>
          </div>

          <div className="footer-items">
            <h3>Contact</h3>
            {/* <div className="footer-logo-links">
              <RiHomeHeartLine />
              <span className="hovering-pointer">Thoraipakkam,Chennai.</span>
            </div> */}

            <div className="footer-logo-links">
              <GoMail />
              <span className="hovering-pointer" onClick={clipboardCopy}>
                {emailAddress}
              </span>
            </div>
          </div>
        </div>
        <div className="footer-line"></div>
        <div className="footer-copyright">
          <span>&#169; {year} - loveLease</span>
        </div>
      </Wrapper>
    </>
  );
};

export default Footer;

const Wrapper = styled.footer`
  width: 100%;
  background-color: #333;
  color: white;
  padding: 15px;
  //   position: fixed;
  //   bottom:0;
  // //   bottom: -80vh;
  //   left: 0;
  a {
    color: white;
    text-decoration: none;
    margin: 10%;
  }
  span {
    margin-left: 5px;
  }
  .logo {
    display: inline-block;
  }
  .logo-text {
    display: inline-block;
    margin: 1px;
  }

  .footer-bar {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
  }
  .footer-logo img {
    margin-right: auto;
    width: 181px;
    height: 59px;
  }
  .social-links {
    display: flex;
    justify-content: space-around;
    margin: 13px;
    margin-top: 20px;
  }

  .backtop {
    margin: 10% 0;
  }
  .footer-items {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .hovering-pointer:hover {
    cursor: pointer;
  }
  .footer-logo-links {
    margin: 10px;
    display: flex;
    align-items: center;
  }
  .footer-line {
    height: 2px;
    background-color: white;
    margin-top: 10px;
  }
  .footer-copyright {
    margin-top: 9px;
  }
  @media screen and (max-width: 800px) {
    padding: 10px;
    .footer-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .footer-items {
      align-items: center;
    }
    .social-link {
      margin-bottom: 10px;
    }
  }
`;
