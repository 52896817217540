import React, {  useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
// import { profiles } from "../data/profilesmock";


import axios from "axios";

const PersonPage = () => {
  const { id } = useParams();
  const [filteredData, setUser_Profiles] = useState([]);
  const [error,setError]=useState();
  const [contactError,setcontactError]=useState();
  const [data,setData]=useState('');
  const [loading,setLoading]=useState(false);
  

  const emailInputRef=useRef();



  const getDetailsHanlder=(e)=>{
    setLoading(true);
    e.preventDefault();
    axios.post(`https://backend-love-lease.vercel.app/api/getDetails/loveLease/${id}`,{
      email:emailInputRef.current.value
    }).then((response)=>{
      setData(response.data)
      console.log(response.data);
      setLoading(false);
      emailInputRef.current.value='';
      setTimeout(() => {
        setData('');
      }, 3000);

    }).catch((err)=>{
       setcontactError(err.response.data.message);
       setTimeout(() => {
        setcontactError('');
        
       }, 2000);
       setLoading(false);
    })

  }

  useEffect(() => {
    axios
      .post(`https://backend-love-lease.vercel.app/api/profiles-lists/${id}`)
      .then((response) => {
        setUser_Profiles(response.data);

      })
      .catch((err) => {
        setError(err.message)

        console.log(err);
      });
  }, [id]);

  return (
    <>
      <Wrapper>
        <div>
          <div className="cover-letter">
            <h2>{filteredData.about_yourself}</h2>
          </div>
          {error && <h1>{error}</h1>}
          <div className="profile-basic-details">
            <div className="display-image">
              <img src={filteredData.display_image} alt="display_portrait" />
            </div>
            <div className="user-name">
              <h3>{filteredData.username}</h3>
              <div className="ht-wt">
                <h4>Weight:{filteredData.weight} Kg</h4>
                <h4>Height:{filteredData.height} cm</h4>
              </div>
              <div className="loc-age">
                <h4>City: {filteredData.city}</h4>
                <h4>Age: {filteredData.age}</h4>
              </div>
              <div className="smoker-drinker">
                <h4>Smoker: {filteredData.smoker } </h4>
                <h4>Drinker: {filteredData.drinker  }</h4>
              </div>
              <div className="charger-content">
                <h3>
                &#8377;
                  {filteredData.charge_per_day}/day
                </h3>
              </div>
            </div>
          </div>

          <div className="info-container">
            <div className="country-dob">
              <h4>Country: {filteredData.country}</h4>
              <h4>DOB: {filteredData.dob}</h4>
            </div>
            <div className="social-network">
              <a
                style={{ wordBreak: "break-word" }}
                href={filteredData.linkedinurl}
              >
                <h4>
                  {filteredData.linkedinurl ? (
                    <span>{filteredData.linkedinurl}</span>
                  ) : (
                    ""
                  )}
                </h4>
              </a>
            </div>
          </div>
          <div className="contact-user">
            <input type='email' placeholder="Your Email Address"  ref={emailInputRef}/>
            <button onClick={getDetailsHanlder} disabled={loading && emailInputRef.current.value}>{loading ? 'Loading' : 'Get Details'}</button>
            {setcontactError && <p>{contactError}</p>}
            {data&&<h3>
              Thank You for using loveLease. Soon, You will receive a email
              about users contact.
            </h3>}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default PersonPage;

const Wrapper = styled.div`
  .cover-letter {
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    background: hsl(22, 31%, 88%);
    color: black;
  }
  .profile-basic-details {
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 10px;
  }

  img {
    border:1px solid black;
    border-radius: 50px;
    width: 500px;
    height: 400px;
    margin-right: auto;
  }
  .user-name {
    font-size: 25px;
    margin-top: 30px;
    padding: 10px;
  }
  .ht-wt {
    display: flex;
    margin: 30px 0;
    h4 {
      margin: 0 30px;
    }
  }
  .loc-age {
    display: flex;
    margin: 30px 0;
    h4 {
      margin: 0 30px;
    }
  }
  .smoker-drinker {
    display: flex;
    margin: 30px 0;
    h4 {
      margin: 0 30px;
    }
  }
  .charger-content {
    h3 {
      border-radius: 10px;
      padding: 8px;
      background: #0d0d40;
      color: rgb(245, 67, 219);
    }
  }
  .info-container {
    font-size: 25px;
    margin: 25px;
  }
  .country-dob {
    display: flex;
    justify-content: space-around;
    margin: 15px;
  }
  .contact-user {
    margin: 20px;
    input {
      padding: 10px;
    }
    button {
      padding: 10px;
      font-size: 15px;
      border: none;
      font-weight: 400;
      border-radius: 8px;
      background: #0d0d40;
      color: rgb(245, 67, 219);
    }
    h3 {
      margin: 15px;
    }
    button:hover {
      cursor: pointer;
      color: rgb(255, 191, 0);
    }
  }
  @media screen and (max-width: 950px) {
    .profile-basic-details {
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 400px;
      height: 300px;
    }
    .info-container {
      font-size: 20px;
      margin: 15px;
    }
    .country-dob {
      flex-direction: column;
      h4 {
        margin: 15px 0;
      }
    }
  }
  @media screen and (max-width: 510px) {
    img {
      width: auto;
    }
    .user-name {
      width: auto;
    }
    .ht-wt {
      flex-direction: column;
      h4 {
        margin: 15px;
      }
    }
    .loc-age {
      flex-direction: column;
      h4 {
        margin: 15px;
      }
      width: auto;
    }
    .smoker-drinker {
      flex-direction: column;
      margin: 30px 0;
      h4 {
        margin: 15px;
      }
    }
  }
  @media screen and (max-width: 225px) {
    img {
      width: 100px;
    }
  }
`;
