import React, { useState } from "react";
export const Context = React.createContext();

export const ContextProvider = (props) => {
  const [issidebarOpen, setSidebarOpen] = useState(false);
 


  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <Context.Provider
      value={{
        openSidebar,
        issidebarOpen,
        closeSidebar,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
