import React from "react";
import { styled } from "styled-components";

const Services = () => {
  return (
    <>
      <Wrapper>
        <div>
          <div className="service-header">
            <h3>
              At loveLease, we understand that companionship needs can vary
              greatly from person to person. That's why we offer a range of
              rental categories to cater to your unique preferences and desires.
              Whether you're seeking a romantic partner for a special occasion
              or a friend for a casual outing, we have the perfect category for
              you.
            </h3>
          </div>
          <div className="service-content">
            {/* <div className="content-container-right" style={{ top: "0" }}>
              <h3 className="content-header">Romantic Companions</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    For those seeking a touch of romance and intimacy, our
                    Romantic Companions category is designed to create
                    unforgettable moments. Whether it's a candlelit dinner, a
                    moonlit stroll, or a cozy night in, our Romantic Companions
                    are experts in creating the perfect romantic atmosphere.
                  </li>
                </ul>
              </p>
            </div> */}
            <div className="content-container-left" style={{ top: "0" }}>
              <h3 className="content-header">Social Companions</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    When you're looking for someone to accompany you to social
                    events, parties, or gatherings, our Social Companions
                    category is the ideal choice.
                  </li>
                  <li>
                    Our companions are skilled in social etiquette and
                    conversation, ensuring that you have a charming and engaging
                    partner by your side.
                  </li>
                </ul>
              </p>
            </div>
            <div className="content-container-right" style={{ top: "350px" }}>
              <h3 className="content-header">Romantic Companions</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    For those seeking a touch of romance and intimacy, our
                    Romantic Companions category is designed to create
                    unforgettable moments. Whether it's a candlelit dinner, a
                    moonlit stroll, or a cozy night in, our Romantic Companions
                    are experts in creating the perfect romantic atmosphere.
                  </li>
                </ul>
              </p>
            </div>
            <div className="content-container-left" style={{ top: "700px" }}>
              <h3 className="content-header"> Adventure Companions</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    If you're an adventure enthusiast, our Adventure Companions
                    category is tailored to your spirit of exploration.
                  </li>
                  <li>
                    Whether it's hiking, biking, exploring new destinations, or
                    embarking on thrilling adventures, our companions are ready
                    to join you on your exciting journey.
                  </li>
                </ul>
              </p>
            </div>
            <div className="content-container-right" style={{ top: "1050px" }}>
              <h3 className="content-header">Casual Friends</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    Sometimes, all you need is a friendly face for a casual
                    outing or a relaxed day. Our Casual Friends category is
                    perfect for those seeking platonic companionship for
                    activities such as coffee dates, movie nights, or simply
                    spending quality time together.
                  </li>
                </ul>
              </p>
            </div>
            <div className="content-container-left" style={{ top: "1420px" }}>
              <h3 className="content-header"> Supportive Companions</h3>
              <hr></hr>
              <p className="content-para">
                <ul>
                  <li>
                    In times when you need emotional support, our Supportive
                    Companions category is here for you.
                  </li>
                  <li>
                    Our companions are empathetic listeners and caring
                    individuals who can provide comfort and companionship during
                    difficult moments.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Services;

const Wrapper = styled.div`
  height: 2100px;
  max-height:auto;
  .service-header {
    background: hsl(22, 31%, 88%);
    background: #00E2E2;
    color: #345b5e;
    font-weight: 500;
    margin: 3rem 0;
    padding: 3rem;
  }
  .service-header,h3{
    color:black;
  }
  .service-content {
    margin: 10px;
    position: relative;
  }

  .content-container-right {
    display: block;
    border: 5px solid yellow;
    border-radius: 20px;
    width: 40%;
    height: 300px;
    position: absolute;
    right: 8rem;
    background:#0d0d40;
  }
  .content-container-left {
    display: block;
    border: 5px solid yellow;
    border-radius: 20px;
    width: 40%;
    height: 300px;
    background:#0d0d40;

    position: absolute;
    left: 8rem;
    top: 350px;
  }
  .content-header {
    margin: 10px;
    // color: rgb(255, 191, 0);
    color:white;
  }
  .content-para {
    margin: 20px;
    padding: 10px;
    color: rgb(245, 67, 219);

    text-align: justify;
  }
  @media screen and (max-width: 800px) {
    height:auto;
    .service-header{
        padding:1.5rem;
        // text-align:justify;
    }
    .service-content {
      position: static;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .content-container-right,
    .content-container-left {
      position: static;
      margin: 20px;
      width: 85%;
      height:auto;
    }
  }
`;
