import React from "react";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
// import logo from "../Logo/Purple Yellow Pink Girl Working Animated Portrait Instagram Post.png";
import image from "../Logo/Purple Illustration Romantic Phone Wallpaper.png";
import SeekingAnimation from "../LottieAnimation/SeekingAnimation";


const Home = () => {
  return (
    <>
      <Wrapper>
        <div>
          <div>
            <div className="homepage-header">
              <h1>
                Welcome to loveLease - Where Companionship Meets Convenience
              </h1>
            </div>

            {/* ------header content for the homepage--------------- */}
            <div className="header-para">
              <div className="para-image">
                <SeekingAnimation />
                {/* <img src={logo} alt="thinking-person" className="image-home" /> */}
              </div>
              <div className="para-content">
                <h3>
                  <ul>
                    <li>
                      We understand that modern life can be hectic, and finding
                      meaningful companionship shouldn't be a challenge.
                    </li>
                    <li>
                      That's why we've created a unique platform where you have
                      the power to
                      <span style={{ color: "white" }}> choose,</span>
                      <span style={{ color: "white" }}> customize,</span>and
                      <span style={{ color: "white" }}> craft </span>
                      your own companionship experience.
                    </li>
                  </ul>
                </h3>
              </div>
            </div>

            {/* ------Home page Feature--------- */}
            <div className="homepage-feature">
              <div className="feature-content">
                <h2 style={{ color: "#f543db" }}>International Platform</h2>
                <h4 style={{ color: "#d7d7d7" }}>
                  Available across the world, anyone from any country can create
                  an account and post their listing.
                </h4>
              </div>
              <div className="feature-content">
                <h2 style={{ color: "#ffbf00" }}>No Commission Fees</h2>
                <h4 style={{ color: "#d7d7d7" }}>
                  Unlike our competitors, we take no commission fees from each
                  sale our vendors make.
                </h4>
              </div>
              <div className="feature-content">
                <h2 style={{ color: "#E0FFFF" }}>Free to Join</h2>
                <h4 style={{ color: "#d7d7d7" }}>
                  We do not charge our clients membership fees to view our
                  rental friends’ listings.
                </h4>
              </div>
            </div>

            {/* ------Homepage_ Add Your Profile----------- */}
            <div className="add-profile-container">
              <div className="add-profile-contents">
                <div className="add-profile-header">
                  <h4>Become a friend for Rent</h4>
                </div>
                <div className="add-profile-content">
                  <ul className="add-profile-ul">
                    <li className="add-profile-li">✔ Flexible Work Hours</li>
                    <li className="add-profile-li">
                      ✔ No Commission Taken From Each Sale
                    </li>

                    <li className="add-profile-li">
                      ✔ Purely Platonic Relationships
                    </li>
                  </ul>
                </div>
                <Link to="/add-your-profile" className="add-profile-button">
                  <button className="btn btn--love" type="submit">
                    Add Your Profile
                  </button>
                </Link>
              </div>
              <div className="add-profile-logo">
                <img
                  src={image}
                  alt="Add profile Hands "
                  className="add-profile-image"
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Home;

const Wrapper = styled.div`
  .homepage-header {
    background: #00E2E2;
    padding: 20px;
    margin: 20px 0;
  }
  .header-para {
    display: flex;
    align-items: center;
    background: #e29fb1;
    margin: 4rem 0;
  }
  .image-home {
    width: 400px;
    height: 400px;
  }
  .para-content {
    text-align: justify;
    font-weight: 500;
    font-size: 20px;
    margin: 20px;
    margin-left: 15%;
  }
  .homepage-feature {
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #0d0d40;
    display: flex;
    justify-content: space-around;
  }
  .feature-content {
    h4 {
      margin: 20px;
    }
  }
  .add-profile-container {
    background: #00E2E2;
    display: flex;
    justify-content: space-between;
    margin:20px 0;
  }
  .add-profile-header {
    background: #0d0d40;
    color: rgb(215, 215, 215);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    font-size: 3rem;
  }

  .add-profile-content {
    padding: 20px;
    margin: 10px;
    font-size: 20px;
  }
  .add-profile-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .add-profile-li {
    list-style-type: none;
    margin: 20px;
  }
  .add-profile-image {
    width: 400px;
    height: 450px;
  }
  .btn {
    padding: 10px;
    // font-weight: 300;
    font-size: 25px;
    text-decoration: none;
    text-align: center;
    transition: all 0.5s ease;
    border-radius: 3px;
    border-style: none;
  }

  .btn--love {
    color: #fff;
    // padding-right: 0;
    // background-color: #c0392b;
    background-color: #0d0d40;
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 100%, 0 100%);
  }
  .btn--love:hover {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    .header-para {
      flex-direction: column;
    }
    .homepage-feature {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
    .image-home {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    .add-profile-container{
      flex-direction:column;
    }
    .add-profile-image{
      width:100%;
      height:800px;
    }
  }
`;
