import React from "react";
import { styled } from "styled-components";
import {BsFillArrowThroughHeartFill} from 'react-icons/bs'

const Slider = () => {
  return (
    <>
      <Container className="scrolling-text">
        <marquee>Experience our beta version. The final version is just around the corner! <BsFillArrowThroughHeartFill></BsFillArrowThroughHeartFill></marquee>
      </Container>
    </>
  );
};

export default Slider;

const Container=styled.div`
margin:10px;
font-weight:700;
color:#f11926;
`
const Wrapper = styled.div`
margin:2px;
  .scrolling-text {
    background:red;
    width: 100%;
    overflow: hidden;
    margin: 2px;
  }

  marquee {
    width: 100%;
    white-space: nowrap;
    animation: scrollText 10s linear infinite;
  }
  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;
