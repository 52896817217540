import React from "react";
import { styled } from "styled-components";
import image from "../Logo/Purple Yellow Pink Girl Working Animated Portrait Instagram Post.png";

import { Link } from "react-router-dom";

function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, { type: theBlob.type });
}

const ProfileContainer = ({ value,image }) => {

  
  // const file = blobToFile(value.display_image, `${value.username}.jpg`); // Use the correct file name and type
  // console.log(file)
  // const imageUrl = URL.createObjectURL(file);
  // console.log(imageUrl)
  return (
    <>
      <Wrapper>
        <div className="profile-listing">
          <div className="profile-container">
            <div className="display-image">
              <Link to={`/detail/${value._id}`}>
                <img src={value.display_image} alt="Display-frame" />
              </Link>
              <div className="footer-line"></div>
            </div>
            <div className="profile-name">
              <h3>{value.username}</h3>
            </div>
            <div className="ht-wt">
              <h4>Weight:{value.weight} Kg</h4>
              <h4>Height:{value.height} cm</h4>
            </div>
            <div className="loc-age">
              <h4>City: {value.city}</h4>
              <h4>Age: {value.age}</h4>
            </div>
            <div className="smoker-drinker">
              <h4>Smoker: {value.smoker }</h4>
              <h4>Drinker: {value.drinker }</h4>
            </div>
            <div className="footer-line"></div>
            <div className="charger-content">
              <h2>
              &#8377;
                {value.charge_per_day}/day
              </h2>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ProfileContainer;

const Wrapper = styled.div`
  img {
    width: 350px;
    height: 300px;
  }
  .profile-container {
    background: #00E2E2;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin: 20px;
    word-wrap: break-word;
    width: 400px;
    height: 515px;
  }
  .footer-line {
    height: 2px;
    background-color: black;
    margin-top: 10px;
  }
  .profile-name {
    margin-top: 5px;
    background: #0d0d40;
    color: rgb(255, 191, 0);
    width: auto;
    padding: 10px;
    border-radius: 10px;
  }
  .ht-wt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
  h4 {
    word-wrap: break-word;
  }
  .loc-age {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
  .smoker-drinker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
  @media screen and (max-width: 800px) {
    .profile-container {
      margin: 10px;
      width: 350px;
    }
  }
`;
